$primary: #2664D3;
$primary-active: #0D399A;
$primary-hover-focus: #124DBA;
$secondary: #DCEEFF;
$secondary-hover-focus: #EAF6FF;
$negative: #e43a40;
$gray100: #323237;
$gray80:#65656D;
$gray60: #9898A0;
$gray40: #ccccd4;
$gray10: #f6f6fa;
$custom-control-indicator-size : 1.75rem;
$black :#000000;
$white: #FFFFFF;