/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// testing commit
@import 'variables';
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '~flag-icon-css/css/flag-icon.min.css';
@import '@angular/material/theming';
@import '~bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core(); 

// define a real custom palette (using http://mcg.mbitson.com)
$bv-brand: (
    50: #ffffff,
    100: #dde6f3,
    200: #b4c9e4,
    300: #7fa3d1,
    400: #6992c9,
    500: $primary,
    600: #4072b4,
    700: #38649d,
    800: #305687,
    900: #284770,
    A100: #ffffff,
    A200: #dde6f3,
    A400: #6992c9,
    A700: #38649d,
    contrast: (
        50:  $gray80,
        100:  $gray80,
        200:  $gray80,
        300:  $gray80,
        400:  $gray80,
        500: $gray80,
        600: $gray80,
        700: $gray80,
        800: $gray80,
        900: $gray80,
        A100:  $gray80,
        A200:  $gray80,
        A400:  $gray80,
        A700: $gray80,
    )
);

$bv-orange: (
    50: #ffffff,
    100: #fff7f4,
    200: #fecdbd,
    300: #fc9977,
    400: #fc8259,
    500: $primary-active,
    600: #fa551d,
    700: #f44205,
    800: #d63a04,
    900: #b83204,
    A100: #ffffff,
    A200: #fff7f4,
    A400: #fc8259,
    A700: #f44205,
    contrast: (
        50:  $gray80,
        100:  $gray80,
        200:  $gray80,
        300:  $gray80,
        400:  $gray80,
        500: $gray80,
        600: white,
        700: white,
        800: white,
        900: white,
        A100:  $gray80,
        A200:  $gray80,
        A400:  $gray80,
        A700: $gray80,
    )
);

// mandatory stuff for theming
$bv-palette-primary: mat-palette($bv-brand);
$bv-palette-accent:  mat-palette($bv-orange);

// include the custom theme components into a theme object
$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
@include angular-material-theme($bv-theme);
@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-Light.ttf");
  font-weight: light;
  font-style: normal;
}


@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-LightItalic.ttf");
  font-weight: light;
  font-style: italic;
}


@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-Book.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "3MCircularTT";
  src: url("assets/fonts/3MCircularTT-BookItalic.ttf");
  font-weight: normal;
  font-style: italic;
}

body {
  height: 100%;
  font-family: "3MCircularTT","Helvetica","Arial",sans-serif;
  color: #4b4b4b !important;
  font-size: 14px;
}

.modal{
  z-index: 1056;
}
.text-dark {
  color: #4b4b4b !important;
}
.app-body{ background:url(assets/images/trifectaMesh.svg);}
.form-control {font-size: 1.2rem;}

*{
  font-family: "3MCircularTT","Helvetica","Arial",sans-serif;
}

// Commented for ADA story
// a {
//   outline: 0 !important;
// }

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

:root {
  --fa-primary-color: #4b4b4b;
  --fa-secondary-color: #fff;
}

.fa {
  color: #4b4b4b;
}
.nav-item{
   &.active{
  color:white;
  background-color: #4b4b4b;
   }
}
//Added for ADA
.nav-link{
  &.active{
    color: white;
    background-color: #646262;
   }
}
.mat-select-panel{
  position: absolute;
  top: 20px;
}

.change-report-container th.mat-header-cell {
  color:black;
  font-size: 14px;
  font-weight: bold;
}
.change-report-container .mat-input-element:disabled {
  color:initial;
}
.btn-secondary{
  border: 1px solid #4b4b4b;
}

.fa-calendar:before{
  font-size: 20px;
  margin: 6px;
  cursor: pointer;
}
.fa-white {
  color: #fff;
}

.bg-color-primary {
  background-color: #4b4b4b;
}

.color-primary {
  color: #4b4b4b;
}

// .btn-primary {
//   background-color: #fff;
//   border: 1px solid #4b4b4b;
//   color: #000;
// }

// .btn-secondary {
//   border-radius: 4px;
//   background-color: #4b4b4b;
//   border: 1px solid #F2F2F2;
//   color: #FFF;
// }

.btn {
  font-size: 14px;
  border-radius: 0px;
  min-width: 70px;
}

.avFooter .mat-button.mat-button-disabled.mat-button-disabled.primary-button{
  opacity: .6;
  color:white;
}

.close {
  width: 25px;
  height: 20px;
  font-size: 2rem;
}
/*Dattatraya css*/
.support-btn{
  font-size: inherit;
  }
  .support-btn .btn {
    font-size: 14px;
    border-radius: 4px;
    min-width: 30px;
  }
.selectCardView .close{ opacity: 1; font-size: 2.5rem;}
.selectCardView .card-header {padding: 0.3rem 1.25rem 0rem 1.25rem;}
.selectCardView .card-header-detail .btn{padding: 0rem 0.75rem !important;}
// .btn-primary:hover {
//   color: #fff;
//   background-color: #4b4b4b;
//   border-color: #4b4b4b;
// }
.btn-secondary {
  border-color: #6e6e6e;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}
.btn-secondary:hover .fa,.btn-primary .fa {
  color: #fff;
}
//Added for ADA
.custom-control-input:focus {
  //opacity: 1; //TSDRSAM-1582 Fixed by Harshal K
  border-radius: 3px;
  outline: #333 solid 2px;
}

::-webkit-scrollbar {
  width: 3px;
 }
/* Track */
  ::-webkit-scrollbar-track {
    background: #d6d4d4; 
   }

/* Handle */
::-webkit-scrollbar-thumb {
   background: #4b4b4b; 
 }

 /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #4b4b4b; 
 }

// esri css changes
.esriControlsBR {
  right: 0 !important;
  bottom: 0px !important;
}

//TSDRSAM-1769 
.esriMeasurement .esriButtonHover .dijitButtonNode {
  background: none !important;
}

.esriMeasurement .esriButtonActive .dijitButtonNode {
  background: none !important
}

.esriMeasurement .esriButtonChecked .dijitButtonNode {
  background: none !important
}

.esriMeasurement .esriMeasurementResultLabel {
  color: black !important;
  text-align: left !important;
}

.esriMeasurement .areaIcon {
  background-image: url(assets/icon/main-nav/select_by_extent.svg) !important;
  background-position: center center !important;
}

.esriMeasurement .distanceIcon {
  background-image: url(assets/icon/main-nav/redline.svg) !important;
  background-position: center center !important;
}

.esriMeasurement .locationIcon {
  background-image: url(assets/icon/main-nav/location.svg) !important;
  background-position: center center !important;
}

.esriMeasurement .esriButtonChecked .dijitButtonNode .areaIcon,
.esriMeasurement .esriButtonChecked .dijitButtonNode .distanceIcon,
.esriMeasurement .esriButtonChecked .dijitButtonNode .locationIcon {
  filter: invert(100%);
}
//end
.calcite {
  .BasemapToggle .basemapTitle {
    display: none !important;
  }
  
  .esriMeasurement .esriButtonChecked .dijitButtonNode {
    background-color: $primary-hover-focus !important;
  }
  
  .esriMeasurement .result {
    display: block !important;
    text-align: left;
  }
  
  .esriMeasurement .esriMeasurementTableContainer {
    font-weight: 700;
  }
  
  .esriMeasurement .esriMeasurementResultLabel {
    border-bottom: thin solid #dddddd;
  }
  
  .esriMeasurement .esriToggleButton .dijitButtonText {
    text-align: left;
  }
  
    // .scalebar_bottom-left.esriScalebar {
    //   z-index: 0;
    // }
   .esriOverviewMap .ovwContainer {
      position: fixed !important;
    }
   .LocateButton .zoomLocateButton {
    padding-top: 5px !important;
    box-shadow : none !important;
  }
  
   .LocateButton .loading {
    margin-top: 5px !important;
  }
  
   .LocateButton .zoomLocateButton:active,  .LocateButton .zoomLocateButton:focus {
    background-color: #fff !important;
    outline: 0 !important;
    border:0 none !important;
  }
  
   .esriBasemapGalleryNode {
    color:#000 !important;
  }
  
   .esriBasemapGalleryNode .esriBasemapGalleryThumbnail {
    border: 1px solid #4b4b4b;
    border-radius: 4px;
  }
  // For ADA story start...
  a:focus img.esriBasemapGalleryThumbnail {
    border: 3px solid #b63737;
  }

  button:focus {
    outline: #333 solid 2px;
    border-radius: 4px;
  }
  input[type=radio], input[type=checkbox]:focus {
    outline: #333 solid 2px;
  }
  input.focus .custom-control-input{
    outline: #333 solid 2px;
  }
  span.focus .dijitReset.dijitStretch.dijitButtonContents.dijitDownArrowButton{
    outline: #333 solid 2px;
  }
  .dijitReset .dijitInline .dijitButtonNode :focus{
    border: 2px solid black;
   }
   .dijit .dijitReset .dijitInline .esriButton :focus{
       border: 2px solid black;
   }
   //For ADA Story end...
  
  .esriColorPicker { 
    box-sizing: content-box;
  }
  
  .dijitReset {
    padding: 3px !important;
  }
  .esriMeasurement .esriMeasurementSeparator
  {
    display: inline-block;
  }
  
  
   .esriMeasurement .distanceIcon, 
   .esriMeasurement .areaIcon, 
   .esriMeasurement .locationIcon {
    width: 20px !important;
    height: 20px !important;
  }

   .arcgisSearch,
   .arcgisSearch .searchGroup { 
    border-radius: 2px !important;
  }
  
  .arcgisSearch {
    border: 1px solid $gray40 !important;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
  }
  
   .arcgisSearch .searchBtn.searchToggle,
   .arcgisSearch .searchGroup .searchInput {
    //border-radius: 4px 0 0 4px !important;
    border-radius: 2px !important;
  }
  
  .arcgisSearch .searchBtn.searchSubmit {
    border-radius: 0 4px 4px 0 !important;
  }
// commented by Prashant for GPS Track log 3-Jan-2020
.esriPopup.extended .actionsPane .zoomTo {  
  display: none !important;  
} 
.esriPopup.extended .titleButton.maximize,
.esriPopup.extended .titleButton.prev,
.esriPopup.extended .titleButton.next,
.esriPopup.extended .titleButton.close,
.esriPopup.extended .titlePane .title {  
  display: none !important;  
}
.esriPopup.extended .esriPopupWrapper{
 border-radius: 8px !important;
}
.esriPopup.extended .sizer {
 width: 149px !important;
 }
.esriPopup.extended .titlePane {
 padding: 0px 0px !important;
}
.esriPopup.extended .actionList a:before, 
.esriPopup.extended .titleButton:before, 
.esriPopup.extended .mediaHandle:before{
 border: 2px solid #6e6e6e !important;
 border-radius: 4px;
}

//    .esriScaleLabelDiv{
//    left:1650px;
//  }
//  .esriScalebarMetricLine{
//    left: 1650px;
//  }
//  .esriScalebarEnglishLine{
//    left: 1650px;
//  }

// Added for pointer
.esriPopup .outerPointer.left {
  left :8px !important;
}
.esriPopup .outerPointer.right {
  right : 8px !important;
}
.esriPopup .pointer.bottom {
  bottom: -7px !important;
}

.esriPopup .pointer.top{
  background: #FFF;
  top: -8px !important;
}
.esriPopup .maximize {
  display: none;
}

.esriPopup .close {
  color: #000 !important;
  opacity: 1 !important;
}


.scalebar_bottom-right {
  right: 180px !important;
} 
 .esriScalebarLabel{
   color: blue;
 }
 .esriMeasurement .esriButtonChecked .dijitButtonNode,
 .esriMeasurement .esriButtonHover {
    background: none;
  }
}

.smstimeslider{
    .esriTimeSlider {
      padding: 0 0 10px 0;
    }
    .dijit.dijitReset.dijitInline.dijitButton {
      display: none;
    }
    td.tsTmp {
      width: 95%;
    }
    .dijitRuleLabelH {
      font-weight: 900;
    }
    .timeSliderLabels label {
      margin-right: 20px;
      font-weight: 700;
    }
}

.calcite .dijitSlider .dijitSliderProgressBarH {
  background-color: #4b4b4b;
}
//TSDRSAM-1418
.swal2-styled.swal2-confirm {
background-color: #2664D3 !important;
}
// esri css changes ends ----------------------------------

// .modal-content {
//    border-radius: 4px;
// }

.modal-header {
  background-color: #4b4b4b !important;
  border-radius: 2px;
}

// .tab-content > .active {
//     height: 525px !important;
// }
// .tab-content {
//   border-radius: 4px;
// }
.modal-title {
    color: white;
}
.modalHeight {
    height: 575px !important;
    border-radius: 4px !important;
    font-family: "Segoe UI",
}
/*Dattatraya Css*/
.calcite .esriBasemapGalleryNode.esriBasemapGallerySelectedNode{
  height: 117px !important;
}
.esriAttributeInspector .atiLabel {
  text-align: left;
}
.btnplus{padding: 0rem 0rem 0rem 0.75rem !important;}

.sms-model-height {
  height : calc(100vh - 200px);
  overflow-y: auto;
}
.sms-model-height .badge {
  padding-left: 0;}
.sms-model-height .badge-secondary {
    background-color: transparent;}

    .sms-useradmin-height {
      height : calc(100vh - 160px);
      overflow-y: auto;
    }    

    .arcgisSearch .searchIcon {
      font-size: 17px !important;}
    .calcite .arcgisSearch .searchIcon {
         line-height: 2.10000 !important;
    }
  //(Commented ) This is for ADA story - by Subba
  //   .swal2-styled:focus {
  //     outline: none !important;
  //     box-shadow: none !important;
  // }
  .margin-but-duallist{margin-top: 5%;}
//   .esri-icon-search:before {
//     content: "\e67b";
//     color: #000;
// }
#searchWidgetDiv_input::placeholder {color:$gray80; opacity: 1;}
//Added for ADA
#searchWidgetDiv_input:focus{
  border-radius: 2px;
  outline: $gray40 solid 1px;
}
//TSDRSAM-1767
#searchWidgetDiv_input{
  width: 375px;
 font-size: 16px;
 line-height: 20px;
}

/**Responsive classes */
@media (min-width: 1390px) {
  .mtTableHeight{height: 229px;}
}
@media (min-width: 1280px) and (max-width: 1390px) {
  .mtTableHeight{height: 151px !important;}
}
/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 992px) and (max-width: 1280px) {
  .margin-but-duallist{margin-top: 5%;}
  .mtTableHeight{height: 151px !important;}

}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 992px) {
  .ui-modal {width: auto !important;}
  .margin-but-duallist{margin-top: 7%;}
  .mtTableHeight{height: 151px !important;}
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .ui-modal {width: auto !important;}
  .margin-but-duallist{margin-top: 7%;}
  .mtTableHeight{height: 151px !important;}
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ui-modal {width: auto !important;}
  .mtTableHeight{height: 151px !important;}
}

.borderTB{border: 2px solid #999999; border-right: none; border-left: none; padding: 10px 0px 10px 0px; margin-bottom: 10px;}
  div#create-edit-task label{ text-align: left;display: block;}
  div#createtask form label{ text-align: left; display: block;} 

  ::-webkit-scrollbar {
    width: 6px;
    height: 9px;
    
}
.createTaskBtn i.fa-wrench{font-size: 19px;}
#frmCreateTask .form-container{padding: 0px;}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{
  box-shadow: 0 0 0 0.2rem rgba(#4b4b4b, 0.5);
}
.myMaintananceDiv{clear: both;
  display: block;
  width: 100%;}

.formbg-d2{background-color: white !important;}

/*Table css override*/
.esriSignInDialog{ z-index: 2150 !important;}
.esri-feature-table .esri-feature-table-menu{height: 20px !important;}
.esri-feature-table-menu .esri-feature-table-menu-item {
  height: 20px !important;
  line-height: 20px !important;
  margin: 0;
}
.esri-feature-table .dgrid-row .dgrid-cell{padding: 0px 3px;}
.dgrid-cell-padding {padding: 0px 3px !important;}
.h-75 {
  height: unset !important;
}

.calcite .esriScalebar {
  z-index: 0 !important;
}

.modal-dialog{
  height:95%;
}

/*
.shadow {
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.3) !important;
}
*/
.modal-content{box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.3) !important;height: 90%;}

.border-success {
  border-color: #4d70db !important;
}

.table td{
  vertical-align: middle;
}


/*Heading Styles */
.h7{
  font-size:14px;
  line-height: 18px; 
  font-weight: bold;
}
.h6{
  font-size:16px;
  line-height: 20px; 
  font-weight: bold;
  color: $gray100;
}
.h5{
  font-size:18px;
  line-height: 22px; 
  font-weight: bold;
}
.h4-heading{
  font-size:20px;
  line-height: 24px; 
  font-weight: bold;
}
.h2-bold{
  font-size:28px;
  line-height: 32px; 
  font-weight: bold;
}

/*Body Styles */
.xxsmall{
  font-size:10px;
  line-height: 14px;  
  font-weight:bold;
}

.xsmall{
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
}

.small{
  font-size: 14px;
  line-height: 18px;
}

.regular{
  font-size:16px;
  line-height:20px;
}

.active-border{
  border: 1.5px solid $primary;
}

.hover-focus-border{
  border: 1px solid $primary;
}

.negative-border{
  border: 1px solid $negative;
}
.calcite .esriBasemapGalleryNode {
  width: 139px !important;
  font-weight: bold !important;
  line-height:14px !important;
}
.calcite .esriBasemapGalleryNode .esriBasemapGalleryThumbnail{
  width:95% !important;
  height: auto !important;
}
.dropdown-icons{
  position: absolute;
  right: 0;
  top: 5px;
}
.calcite .arcgisSearch .searchMenu li.active {
  color: $white !important;
  background-color: $primary-hover-focus !important;
}


//MAT BUTTON
.querybound .mat-button{
  line-height: 0px;
}

//TAB GROUPS AND TABS
.mat-tab-label{
  min-width: 90px !important;
  opacity: 1 !important;
}

.mat-ink-bar {
  height: 4px !important;
}

.mat-tab-label{
  &[aria-selected="false"]{
  color: $gray80;
  font-size: 16px;
  line-height: 20px;
}
}
.swal2-modal{
  .swal2-actions{
    button{
      font-weight: bold;
    }
  }

  .header-swal-override{
    display: flex;
    flex-direction: column-reverse;
  }
  .close-button-class {
    color: $black;
  }
  
  .cancel-button-class {
    margin-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 18px;
    background-color: $white !important;
    height: 32px;
    color: $primary !important;
    outline: $gray60 1px solid;
  }
  }
.mat-tab-header{
  background: white;
}

.mat-tab-label {
  &[aria-selected="true"]{
    color: $gray100 !important;
    font-weight: bold !important;
    font-size: 16px;
    line-height: 20px;
  }
}


#mat-tab-content-2-1 > div{
  overflow-y: hidden !important;
}
#mat-tab-content-2-2 > div {
  overflow-y:scroll !important;
}
.asset-detail.mat-tab-body-content{
  overflow:hidden !important;
}

//MATERIAL DROPDOWNS

.special-dropdown .mat-form-field-appearance-outline .mat-form-field-infix {
  padding:0px !important;
}
.alias-input{
  .mat-form-field-wrapper{
    padding-bottom: 0px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix{
    padding: 6px !important;;
  }
}
.modal-body .mat-form-field{
  font-size: 14px !important;
  line-height: 16px !important;
}
.avDetailScrollArea{
  .mat-form-field{
    width:100%;
  }
}
.mat-form-field{
  font-size: 14px !important;
  line-height: 16px !important;
}
.mat-form-field-label {
  font-weight: 600 !important;
}

#help_tab .mat-form-field-wrapper{
  padding-bottom:0px;
}
.special-dropdown .ng-select.ng-select-single .ng-select-container {
  border: none !important;
}


.mat-option-text{
  font-size: 12px !important;
  line-height: 16px !important;
}

//MATERIAL SELECT
.mat-select-arrow {
 border:none !important;
}

.mat-form-field-flex{
  align-items: center !important;
}
//TSDRSAM-1363-1364
#frmCreateTask .mat-form-field {
  width: 100%;
}

.form-manageorder .mat-form-field {
  width: 100%;
}

.divider-aftersubheading {
  margin: 6px;
}

.task-subheading {
  padding-left: 16px;
  margin-top: 12px;
}

.task-button.inverse-button {
  width: 100%;
  line-height: 40px;
  font-size: 12px;
 
}

.input-disable .mat-form-field-outline  {
  background-color: #F1F1F6 ;
  border-radius: 5px;  
}

 .mat-stroked-button.mat-button-disabled.mat-button-disabled {
   color: $gray100;
 }
//TSDRSAM-1422
.divider-assessment{
  width:100%;
  height:0.5px;
  background: #dddddd;
}
.modal-footer-assessment
{
  padding-top: 8px;
  justify-content: center;
}

.not-active {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.inverse-button {
  color: $primary;
  background-color: $white;
  min-width: 48px;
  min-height: 32px;
}

.primary-button {
  color: $white;
  background-color: $primary;
  min-width: 48px;
  min-height: 32px;
}

.export-type-btn {
  border: none;
  background: #f6f6fa;
  font-family: "3MCircularTT", "Helvetica", "Arial", sans-serif;
  display:flex;
  align-items: end;
  .mat-menu-item {

    font-family: "3MCircularTT", "Helvetica", "Arial", sans-serif;
  }
}

.mat-tab-label {
  flex: 1;
}

.layer-select> .mat-form-field > .mat-form-field-wrapper {
  padding-bottom:0px !important;
}

.querybound .mat-tab-body-content {
  overflow-y:auto !important;
}
// this will only take affect on an iOS device
@supports (-webkit-touch-callout: none){
  svg {
    vertical-align: top !important;
    -webkit-transform: translate(0px,0px) !important; /* Safari and Chrome */
    transform: translate(0px, 0px) !important;
  }
}

@media (min-height: 595px) {
  .sms-model-height {
    height: calc(100vh - 250px);
  }
}

@media (min-height: 661px) {
  .sms-model-height {
    height: calc(100vh - 300px);
  }
}
.idle-modal .modal-content {
  height: auto;
} 